import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import './Toolbar.scss';

const Toolbar = () => {


  return (
    <div className="toolbar-wrapper">
      <a href="/Resume-Dec_2022.pdf" target="_blank" rel="noreferrer" title='Resume'>
        <FontAwesomeIcon icon={faFilePdf}/>
      </a>
      <a href="https://github.com/jrtnq514" target="_blank" rel="noreferrer" title='Github'>
        <FontAwesomeIcon icon={faGithubSquare} />
      </a>
    </div>
  );
};

export default Toolbar;