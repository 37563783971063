import './About.scss';

const About = () => {

  return (
    <div className="about-wrapper">
      <div className="about_left">
        <div className="white-border">
          <div className="white-border">
            <div className="white-border">
              <img src="./about-img.jpg" alt="me" />
            </div>
          </div>
        </div>
      </div>
      <div className="about_right">
        <p>
          Full stack engineer who loves working in a collaborative environment to solve challenging problems. I am highly self-motivated, always seeking to learn more, and help others.
          Currently based in the Kansas City area. In my free time I run an Esty Shop with my wife, build other sites for clients, and dabble in side projects.
          For better or worse I like to try and do everything myself at least once, which has led to a growing list of hobbies.
        </p>
      </div>
    </div>
  );
}

export default About;