import { Parallax } from 'react-scroll-parallax';
import './Experience.scss';

const Experience = () => {
  const experiences = [
    {
      title: 'C2FO',
      dates: '2021 - Present',
      logoUrl: '',
      description: 'Focused on solutions to allow partner platforms to integrate with C2FO. This included embeddable user experiences and APIs.'
    },
    {
      title: 'Commerce Bank',
      dates: '2014 - 2021',
      logoUrl: '',
      description: 'Led frontend development for new and current consumer facing applications. Worked closely with several bank teams to implement frontend solutions. Setup shared libraries to improve consistency across applications. Developed working prototypes for user testing. Championed accessibility practices. Mentored junior engineers.' 
    }
  ];



  return (
    <div className="ex-wrapper">
      <div className="ex-path"></div>
      <div className="ex-path ex-path_left"></div>
      <div className="ex-path ex-path_right"></div>
      { experiences.map((exp) => (
        <div key={exp.title} className="ex-item">
          <div className="ex-item-top">
            <h3 className="ex-item_title">{exp.title}</h3>
            <label>{exp.dates}</label>
          </div>
          <p>{exp.description}</p>
        </div>
      ))}
    </div>
  );
}

export default Experience;