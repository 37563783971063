import Experience from '../Experience/Experience';
import Intro from '../Intro/Intro';
import Skills from '../Skills/Skills';
import Toolbar from '../Toolbar/Toolbar';
import About from '../About/About';
import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Intro/>
      <About/>
      <Experience/>
      <Skills/>
      <Toolbar/>
    </div>
  );
}

export default App;
