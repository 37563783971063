import './Skills.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faGithubSquare,
  faNodeJs,
  faSquareJs,
  faVuejs,
  faReact,
  faCss3,
  faHtml5,
  faDocker,
  faGit,
  faPiedPiperHat,
  faAws,
} from '@fortawesome/free-brands-svg-icons';

const Skills = () => {
  const skills = [
    faGithubSquare,
    faNodeJs,
    faSquareJs,
    faVuejs,
    faReact,
    faCss3,
    faHtml5,
    faDocker,
    faGit,
    faPiedPiperHat,
    faAws
  ]


  return (
    <div className="skills-wrapper">
      <div className="skill-item-wrapper">
        { skills.map((icon) => (
          <div className="skill-item">
            <FontAwesomeIcon icon={icon}/>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;