import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import './Intro.scss';
import { useCallback } from "react";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-tsparticles";
import { loadLinksPreset } from "tsparticles-preset-links";
import { loadFull } from 'tsparticles';

const Intro = () => {
  
  const particlesInit = useCallback(async (engine: Engine) => {
    // console.log(engine);
    await loadLinksPreset(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container);
  }, []);
  
  return (
    <div className="intro-wrapper">
      <Particles id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          preset: 'links',
          background: {
            opacity: 0
          },
          fullScreen: {
            enable: false
          },
          interactivity: {
            events: {
              onDiv: {
                enable: true,
                selectors: ['.intro-title-wrapper', '.intro-scroll-tip'],
                mode: 'repulse',
                type: 'circle',
              }
            }
          }
        }} />
      <div className="intro-title-wrapper">
        <h1 className='intro-title'>Josh Taylor</h1>
        <h3 className='intro-subtitle'>&lt;Software Engineer&gt;</h3>
      </div>
      <div className="intro-scroll-tip">
        <h4>Work in progress...</h4>
        <FontAwesomeIcon className='intro-scroll-arrows' icon={faAnglesDown} />
      </div>
    </div>
  );
};

export default Intro;